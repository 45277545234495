* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.site-header {
  display: block;
  margin: auto;
  border: 1px solid grey;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#293a55;
  background-color: #9cc2e0;
}

table {
  width: 90%;
  margin: auto;
  border-collapse: collapse;
  font-family: "Alegreya", serif;
  font-size: 0.9rem;
}

.table-container{
  padding-bottom: 20px;
  overflow-x: scroll; 
  overflow: hidden;
}

thead {
  background: #9cc2e0;
  color: #fff;
  letter-spacing: 1px;
}

tbody {
  background: #fff;
  font-weight: 700;
}

td,
th {
  border: 1px solid #ddd;
  text-align: center;
  padding: 8px;
}

.table-title {
  margin-bottom: 0.5rem;
  text-align: left;
  font-weight: 700;
  letter-spacing: 1px;
  color: #fff;
  padding-left: 5%;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  min-width: 250px;
  margin: auto;
  color:#293a55;
  background-color: #9cc2e0;
  padding: 1rem;
  line-height: 1.4;
  border-radius: 10px;
  padding-right: 10px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(138, 163, 219, 0.389);
  cursor:pointer;
}

.container {
  padding: 2px 16px;
}

.search-content{
  text-align: center;
}

.search-input {
  font-size: 1rem;
  color: rgb(255, 255, 255);
  border: 1px solid #9cc2e0;
  background-color: transparent;
  padding: 0.5rem;
  width: 100%;
}

.btn {
  display: block;
  margin: auto;
  box-shadow: 0px 8px 15px #293a55;
  border: none;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 16px;
  cursor: pointer;
  background-color: #9cc2e0;
}

.btn:hover {
  box-shadow: 0 8px 16px 0 rgba(138, 163, 219, 0.389);
  cursor:pointer;
}


.player-div {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.player-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5;
}

.player-name {
  font-size: 2rem;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 1rem;
  color: #fff;
}

.player-detail {
  font-size: 1.25rem;
  color: #fff;
}

.card-results-container {
  margin-top: 2rem;
  display:inline;  
  align-items: center;
}

label, input {
  display: block;
}

label {
  margin-bottom: 20px;
}

li{
  display: inline-block;
  padding: 10px 40px;
}

.spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.spinner-img {
  display: block;
  width: 200px;
  margin: auto;
}

@media only screen and (max-width: 768px) {

  .table-container{
    overflow-x: scroll;
  }

  .card-results-container {
    margin-top: 1rem;
    display:inline-block;  
    align-items: center;
    text-align: center;
  }

  .card {
    font-size: 18px;
    min-width: 300px;
  }

  .search-input {
    padding: 0.5rem 1rem;
  }

  .btn {
    align-items: center;
  }
  li{
    display: inline-block;
  }
  .bounce-img {
    width: 400px;
  }
  .spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .spinner-img {
    width: 400px;
  }
}

